cs.ns("app.ui.trait.root.hash");
app.ui.trait.root.hash.view = cs.trait({
    protos: {

        registerEventBindings () {
            this.base();

            try {
                // enable a hash change listener to act for user changes
                window.addEventListener('hashchange', (e) => {
                    cs(this).value("event:hashChange", e && e.newURL ? e.newURL : e.currentTarget.location.hash);
                }, false);
            } catch (ex) {
            }
        }

    }
})